import React from 'react'
import { graphql } from 'gatsby'

import { MixCard } from '../types'
import { Mixes as MixesPageComponent } from '../components/pages'

const Mixes: React.FC<Props> = ({ data, location }) => {
  const mixes: MixCard[] = data.mixes.nodes.map((mix: MixesNode) => {
    return {
      title: `${mix.catNumber} - ${mix.artist}`,
      slug: `mixes/${mix.catNumber}-${mix.artist.toLowerCase()}`,
      gatsbyImageData: mix.image.gatsbyImageData
    }
  })

  return <MixesPageComponent mixes={mixes} location={location} />
}

export default Mixes

export const query = graphql`
  query MixPageQuery {
    mixes: allContentfulButterMix(sort: { fields: catNumber, order: DESC }) {
      nodes {
        catNumber
        artist
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
  }
`

interface Props {
  data: {
    mixes: {
      nodes: MixesNode[]
    }
  }
  location: any
}

interface MixesNode {
  catNumber: number
  artist: string
  image: {
    gatsbyImageData: any
  }
}
